import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NbAuthService } from '@nebular/auth';

@Injectable()
export class AuthGuard implements CanActivate
{
    constructor(
        private authService: NbAuthService,
        private router: Router
    )
    {
    }

    canActivate(): Observable<boolean>
    {
        return this.authService.isAuthenticatedOrRefresh()
            .pipe(
                tap((authenticated) =>
                {
                    if (!authenticated)
                    {
                        this.router.navigate(['auth'], { replaceUrl: true, });
                    }
                })
            );
    }
}
