import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FooterComponent, LayoutComponent, LeftMenuComponent, TopBarComponent, ToastComponent } from '@theme/components';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faBarcode,
    faCamera,
    faFile,
    faList,
    faPhone,
    faShoppingBasket,
    faUsers,
    faShoppingCart,
    faTruck,
    faChartLine,
    faCog,
    faChartBar,
} from '@fortawesome/free-solid-svg-icons';

import { DEFAULT_THEME, NbThemeModule } from '@nebular/theme';

import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { Footer, Header, MessageService, PrimeTemplate } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FieldsetModule } from 'primeng/fieldset';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ChartModule } from 'primeng/chart';
import { DataViewModule } from 'primeng/dataview';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

const PN_MODULES = [
    TableModule,
    CardModule,
    ToastModule,
    InputTextModule,
    InputNumberModule,
    RippleModule,
    MultiSelectModule,
    DynamicDialogModule,
    DropdownModule,
    ToolbarModule,
    SidebarModule,
    ButtonModule,
    CalendarModule,
    FieldsetModule,
    MenuModule,
    InputTextareaModule,
    CheckboxModule,
    TooltipModule,
    FileUploadModule,
    GalleriaModule,
    ChartModule,
    PaginatorModule,
    DataViewModule,
    DialogModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
];

const COMPONENTS = [
    LayoutComponent,
    TopBarComponent,
    LeftMenuComponent,
    FooterComponent,
    ToastComponent,
];

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, FontAwesomeModule, ...PN_MODULES],
    exports: [CommonModule, FormsModule, ...COMPONENTS, FontAwesomeModule, ...PN_MODULES],
    declarations: [...COMPONENTS],
})
export class ThemeModule
{
    constructor(
        private faIconLibrary: FaIconLibrary,
    )
    {
        const icons: IconDefinition[] = [
            faTruck,
            faShoppingCart,
            faPhone,
            faBarcode,
            faShoppingBasket,
            faList,
            faFile,
            faCamera,
            faChartLine,
            faCog,
            faUsers,
            faChartBar,
        ];
        this.faIconLibrary.addIcons(...icons);
    }

    static forRoot(): ModuleWithProviders<ThemeModule>
    {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot(
                    {
                        name: 'default',
                    },
                    [DEFAULT_THEME],
                ).providers,
                DialogService,
                ConfirmationService,
                Header,
                Footer,
                DatePipe,
                PrimeTemplate,
                MessageService,
            ],
        };
    }
}
